import AvatarWithStatus from "@/components/AvatarWithStatus";
import Loading from "@/components/Loading/Index";
import ModalForm from "@/elements/ModalForm";
import UseFirebase from "@/hooks/firebase/useFirebase";
import UseStorage from "@/hooks/useStorage";
import { AppContext } from "@/models/AppStateProvider";
import { DataContext } from "@/models/DataProvider";
import { UxContext } from "@/models/UxStateProvider";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import {
  Box,
  FormLabel,
  List,
  ListItemButton,
  Stack,
  Switch,
  Typography,
  useTheme,
} from "@mui/material";
import { useFlags } from "launchdarkly-react-client-sdk";
import React, { useState } from "react";
import { useParams } from "react-router-dom";

export default function WorkspaceUserProfile() {
  const { client, flags, setFlags } = React.useContext(AppContext);
  const { myAccount } = React.useContext(DataContext);
  const { setUserFeedbackModalOpen } = React.useContext(UxContext);
  const [loggingOut, setLoggingOut] = useState<boolean>(false);
  const theme = useTheme();
  const params = useParams();
  const { showDebugMenu } = useFlags();
  const { deleteActiveToken } = UseFirebase();
  const { clearLocalStorage } = UseStorage();

  const handleFlag = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFlags({ ...flags, [event.target.name]: event.target.checked });
  };

  const handleSubmitFeedback = () => {
    setUserFeedbackModalOpen(true);
  };

  const handleLogout = async () => {
    setLoggingOut(() => true);
    await deleteActiveToken();
    clearLocalStorage();
    client.logout();
  };

  const readableFlags = Object.keys(flags).map((flag) => ({
    key: flag,
    text: flag.replace(/([a-z])([A-Z])/g, "$1 $2"),
  }));

  const profileActions = [
    {
      text: "Submit Feedback",
      key: "submit-feedback",
      action: handleSubmitFeedback,
      icon: <KeyboardArrowRightIcon />,
    },
    {
      text: "Sign Out",
      key: "sign-out",
      action: handleLogout,
      icon: <ExitToAppIcon />,
    },
  ];

  return (
    <>
      <ModalForm open={loggingOut} allowClose={false}>
        <Box sx={{ display: "flex" }}>
          <Loading variant="white" size="large" baseSize={6} />{" "}
          <Box sx={{ ml: 2 }}>Signing Out</Box>
        </Box>
      </ModalForm>
      <Box sx={{ p: 2, pr: 0.75, position: "relative", height: "100%" }}>
        <Box sx={{ p: 2 }}>
          <Stack
            sx={{
              flexDirection: "row",
              alignItems: "center",
              gap: 1.5,
            }}
          >
            <AvatarWithStatus accountId={myAccount?.id} />
            <Typography sx={{ fontWeight: 500, fontSize: "1.25rem" }}>
              {myAccount?.name}
            </Typography>
          </Stack>
        </Box>

        <List sx={{ p: 0 }} className="scrollable-content">
          {profileActions.map((item) => {
            if (item) {
              return (
                <ListItemButton
                  key={item.key}
                  aria-label={`Navigation to ${item.text}`}
                  onClick={item.action}
                  disableRipple={true}
                  sx={{
                    width: "100%",
                    borderRadius: "8px",
                    justifyContent: "space-between",
                    wordBreak: "break-word",
                    p: 2,
                    fontSize: "1.125rem",
                    fontWeight: 500,
                  }}
                >
                  <Box
                    sx={{
                      flexGrow: 1,
                      textAlign: "left",
                      justifyContent: "start",
                    }}
                  >
                    {item.text}
                  </Box>
                  <Box
                    sx={{
                      flex: "0 1 auto",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {item.icon}
                  </Box>
                </ListItemButton>
              );
            }
          })}
          {showDebugMenu ? (
            <Box sx={{ p: 1, borderRadius: 1 }}>
              <Typography variant="h5" component="h2" fontWeight={700}>
                Debug Menu
              </Typography>
              <Stack sx={{ p: 2, gap: 1 }}>
                {readableFlags.map((flag) => (
                  <FormLabel
                    sx={{
                      display: "flex",
                      cursor: "pointer",
                      alignItems: "center",
                    }}
                    key={flag.key}
                  >
                    <Switch
                      checked={flags[flag.key]}
                      onChange={handleFlag}
                      name={flag.key}
                      sx={{ mr: 2 }}
                    />
                    <Box sx={{ textTransform: "capitalize" }}>{flag.text}</Box>
                  </FormLabel>
                ))}
              </Stack>
            </Box>
          ) : null}
        </List>
      </Box>
    </>
  );
}
